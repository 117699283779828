import React, { FC } from 'react';
import { IPage } from '../../types';
import { PageBody, TextTitle } from '@pypestream/design-system';

export const OrganizationDetails: FC<IPage> = () => {
  return (
    <PageBody background="none">
      <TextTitle i18nKey="manager/organizations:orgDetails.title">
        Org Details
      </TextTitle>
      Page for org details.
    </PageBody>
  );
};
