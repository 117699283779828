import React from 'react';
import { AppletCardAvatar, Toggle, Stack } from '@pypestream/design-system';
import { WidgetProps } from '@rjsf/utils';
import { AppletStatus } from '@pypestream/api-services';
import { AppletInstance } from '@pypestream/api-services/dist/generated/graphql.urql';
import { parseISO } from 'date-fns';

export const StatusWidget = function (
  props: WidgetProps & Pick<AppletInstance, 'updatedByUser' | 'updatedAt'>
) {
  return (
    <Stack justifyContent="space-between">
      <Toggle
        value={AppletStatus.Active}
        checked={props.value === AppletStatus.Active}
        onChange={(e) => {
          props.onChange(
            e.target.checked ? AppletStatus.Active : AppletStatus.Inactive
          );
        }}
      />
      <div>
        <AppletCardAvatar
          date={parseISO(props.updatedAt)}
          slot="footer"
          label={`${props.updatedByUser?.firstName} ${props.updatedByUser?.lastName}`}
          src={props.updatedByUser?.picture || ''}
        />
      </div>
    </Stack>
  );
};
