import { AppletInstance, AppletStatus } from '@pypestream/api-services';
import {
  AppletCard,
  AppletCardAvatar,
  AppletCardInfo,
  AppletCardTitle,
  Button,
  Icon,
  Modal,
  ModalIcon,
  ModalProps,
  Spacer,
  Stack,
  TextBody,
  TextSubtitle,
  TextTitle,
} from '@pypestream/design-system';
import React, { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  sendManagerEvent,
  useGlobalAppCtxSelector,
} from '../../../../xstate/app.xstate';
import { AppletModal } from './applet-modal';
import { TranslationComponent } from '@pypestream/translations';
import { parseISO } from 'date-fns';

export const EnvironmentApplets = ({
  environmentId,
  applets,
  accountId,
  projectId,
  disabled,
}: {
  environmentId: string;
  applets: Array<AppletInstance>;
  accountId: string | undefined;
  projectId: string | undefined;
  disabled?: boolean;
}) => {
  const modalRef = useRef<ModalProps>(null);
  const navigate = useNavigate();
  const enableAppletPrototype = useGlobalAppCtxSelector(
    (ctx) => !!(ctx.featureFlags && ctx.featureFlags['applet-prototype'])
  );
  const [selectedAppletId, setSelectedAppletId] =
    useState<AppletInstance['id']>();

  const isModalOpened = !!selectedAppletId;
  const selectedApplet = applets.find((a) => a.id === selectedAppletId);
  const closeModal = () => setSelectedAppletId(undefined);
  return (
    <>
      {environmentId && (
        <AppletModal modalRef={modalRef} environmentId={environmentId} />
      )}
      <Spacer size="small" />

      {enableAppletPrototype && (
        <>
          <Stack alignItems="center" justifyContent="space-between">
            <Spacer>
              <TextTitle
                size="xsmall"
                i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.title"
              >
                Web Applets
              </TextTitle>
              <TextSubtitle
                size="small"
                variant="tertiary"
                i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.description"
              >
                The Pypestream Applet is the runtime environment for Pypestream
                platform functionality on a given website.
              </TextSubtitle>
            </Spacer>

            <Button
              disabled={disabled}
              variant="ghost"
              size="large"
              onClick={(e) => {
                if (modalRef.current) {
                  modalRef.current.open = true;
                }
              }}
            >
              <TranslationComponent i18nKey="manager/projects:projectDetails.environmentsTab.appletsSection.addButton">
                Add Applet
              </TranslationComponent>
              <Icon slot="prefix" name="add" />
            </Button>
          </Stack>
          <Spacer size="large" />
          <Modal
            open={isModalOpened}
            size="medium"
            stayOnClickOutside
            stayOnEsc
            onClose={closeModal}
          >
            <ModalIcon
              name="error"
              slot="header"
              style={{ display: 'flex', justifyContent: 'center' }}
            />
            <Stack slot="header" alignItems="center" direction="column">
              <TextTitle
                size="small"
                i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.title"
              >
                Are you sure?
              </TextTitle>
              <TextBody variant="secondary">
                <TranslationComponent i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.description">
                  Update Applet Status Description
                </TranslationComponent>
              </TextBody>
            </Stack>
            <Stack slot="footer" justifyContent="end">
              <Button
                variant="ghost"
                size="large"
                type="button"
                onClick={closeModal}
                i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.cancelButton"
              >
                Cancel
              </Button>
              <Button
                size="large"
                variant="warning"
                data-cy="delete-project"
                type="button"
                onClick={() => {
                  if (!selectedAppletId || !selectedApplet) {
                    closeModal();
                    return;
                  }
                  sendManagerEvent({
                    type: 'manager.updateApplet',
                    status:
                      selectedApplet?.status === AppletStatus.Active
                        ? AppletStatus.Inactive
                        : AppletStatus.Active,
                    updateAppletInstanceId: selectedAppletId,
                  });
                  closeModal();
                }}
                i18nKey="manager/projects:projectDetails.updateAppletInstanceStatusModal.confirmButton"
              >
                Update Applet
              </Button>
            </Stack>
          </Modal>

          {applets?.map(
            ({
              id,
              status,
              triggers,
              actions,
              name,
              updatedByUser,
              updatedAt,
            }) => (
              <Fragment key={id !== selectedAppletId ? id : `selected_${id}`}>
                <Spacer size="large" />
                <AppletCard
                  checked={status === AppletStatus.Active}
                  onToggleClick={() => setSelectedAppletId(id)}
                  href={`/organization/${accountId}/projects/${projectId}/environments/${environmentId}/applets/${id}`}
                >
                  <AppletCardTitle label={name || 'Untitled'}></AppletCardTitle>
                  <AppletCardInfo label="Trigger">
                    {triggers.length
                      ? triggers
                          .map((t) => t?.packageVersion?.package?.displayName)
                          .join(', ')
                      : "You haven't set a triggers"}
                  </AppletCardInfo>
                  <AppletCardInfo label="Actions">
                    {actions.length
                      ? actions
                          .map((a) => a?.packageVersion?.package?.displayName)
                          .join(', ')
                      : "You haven't set an actions"}
                  </AppletCardInfo>
                  <AppletCardAvatar
                    date={parseISO(updatedAt)}
                    slot="footer"
                    label={`${updatedByUser?.firstName} ${updatedByUser?.lastName}`}
                    src={updatedByUser?.picture || ''}
                  />
                </AppletCard>
              </Fragment>
            )
          )}
        </>
      )}
    </>
  );
};
