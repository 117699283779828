import { AppletInstance } from '@pypestream/api-services';
import {
  Button,
  JSONSchemaForm,
  PageSection,
  Spacer,
} from '@pypestream/design-system';
import {
  GetAppletInstancesByProjectIdQuery,
  Trigger,
  UpdateAppletInstanceMutationVariables,
} from '@pypestream/api-services/urql';
import { IChangeEvent } from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import React, { useEffect } from 'react';
import { PartialDeep } from 'type-fest';
import {
  ActionPackage,
  ActionsWidget,
  CodeBlockWidget,
  RadioGroupWidget,
  TriggersWidget,
  StatusWidget,
} from './widgets';
import { AppBuilderSchema } from './app-builder-schema';
import { ConditionQueryBuilder } from './fields';
import { AddButton } from './templates/add-button';
import { SubmitButton } from './templates/submit-button';
import { TitleFieldTemplate } from './templates/title-field';
import { ArrayFieldTitleTemplate } from './templates/array-field-title';
import { ArrayFieldDescriptionTemplate } from './templates/array-field-description';
import { CustomFieldTemplate } from './templates/field-template';
import { ObjectFieldTemplate } from './templates/object-field';
import { BaseInputTemplate } from './templates/base-input';

const fields = {
  ConditionQueryBuilder: ConditionQueryBuilder,
};

// temporary endpoint.
export const APP_LOADER_MOCKER_URL =
  'https://basic-deno-62-ezkkt0ja7cka.deno.dev';

const Builder = ({
  applet,
  onSubmit,
  includePrerelease,
  onDelete,
}: {
  applet: NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<GetAppletInstancesByProjectIdQuery['admin_']>['projectById']
      >['projectReleaseChannelConfig']
    >[number]['appletInstances']
  >[number];
  onSubmit: (variables: UpdateAppletInstanceMutationVariables) => void;
  includePrerelease?: boolean;
  onDelete?: (id: string) => void;
}) => {
  const [formData, setFormData] = React.useState<
    PartialDeep<AppletInstance, { recurseIntoArrays: true }>[]
  >([]);

  useEffect(() => {
    if (!applet) {
      return;
    }

    setFormData([
      {
        name: applet?.name,
        status: applet?.status,
        description: applet?.description,
        actions: applet?.actions,
        triggers: applet?.triggers,
        options: applet?.options || {},
        condition: applet?.condition ? applet?.condition?.options : undefined,
      },
    ]);
  }, [applet]);

  const onChangeHandler = async (
    data: IChangeEvent<AppletInstance[]>,
    id: string | undefined
  ) => {
    const appIndex = parseInt(id?.split('_')[1] || '-1');
    if (appIndex >= 0 && data.formData) {
      setFormData([...data.formData]);
    }
  };
  if (!applet || formData?.length === 0) {
    return null;
  }

  const actionPkgName = applet.applet?.applet?.pkgName;
  return (
    <PageSection size="medium" style={{ margin: 32 }}>
      <JSONSchemaForm
        schema={AppBuilderSchema}
        validator={validator}
        formContext={{
          actions: !applet?.actions?.length
            ? [applet.applet?.applet?.pkgName]
            : null,
          includePrerelease,
          updatedByUser: applet.updatedByUser,
          updatedAt: applet.updatedAt,
        }}
        uiSchema={{
          items: {
            'ui:options': {
              label: false, // Hide the label for this field
              addText: 'Add Applet',
            },
            'ui:order':
              actionPkgName?.includes('code-block') || !actionPkgName
                ? [
                    'status',
                    'name',
                    'description',
                    'triggers',
                    'condition',
                    'actions',
                    '*',
                    'options',
                  ]
                : [
                    'status',
                    'name',
                    'description',
                    'actions',
                    'triggers',
                    'condition',
                    '*',
                    'options',
                  ],
            actions: {
              'ui:widget': 'ActionsWidget',
            },
            status: {
              'ui:options': {
                label: false, // Hide the label for this field
              },
              'ui:widget': 'StatusWidget',
            },
            triggers: {
              'ui:options': {
                label: false, // Hide the label for this field
              },
              'ui:widget': 'TriggersWidget',
            },
            condition: {
              'ui:options': {
                label: false, // Hide the label for this field
              },
              query: {
                'ui:field': 'ConditionQueryBuilder',
                items: {
                  'ui:options': {
                    addText: 'Add Condition',
                  },
                },
              },
              advanceSettings: {
                checkImmediately: {
                  'ui:options': {
                    label: false, // Hide the label for this field
                  },
                  'ui:widget': 'RadioGroupWidget',
                },
                timeInterval: {
                  'ui:help': 'ms up to',
                },
                noOfAttempts: {
                  'ui:help': 'Times',
                },
              },
              'ui:order': [
                'advanceSettings',
                'timeInterval',
                'noOfAttempts',
                'query',
              ],
            },
          },
          'ui:options': {
            addable: false,
            removable: false,
          },
        }}
        fields={fields}
        widgets={{
          CodeBlockWidget: CodeBlockWidget,
          ActionsWidget: ActionsWidget,
          RadioGroupWidget: RadioGroupWidget,
          TriggersWidget: TriggersWidget,
          StatusWidget: (props) => (
            <StatusWidget
              {...props}
              updatedAt={applet.updatedAt}
              // @ts-expect-error This should be resolved once the 'deleted-applet' branch is merged.
              updatedByUser={applet.updatedByUser}
            />
          ),
        }}
        onChange={onChangeHandler}
        onSubmit={async (data) => {
          const appletInstance = data.formData[0];
          if (!applet.id) {
            alert('please provide applet instance id');
            return;
          }
          const variables: UpdateAppletInstanceMutationVariables = {
            name: appletInstance.name,
            description: appletInstance.description,
            updateAppletInstanceId: applet.id,
            status: appletInstance.status,
            triggers: appletInstance.triggers.map((t: Trigger) => ({
              packageVersionId: t.packageVersionId,
              options: t.options,
              name: t.name,
              description: t.description,
            })),
            condition: appletInstance.condition,
            actions: appletInstance.actions.map((t: ActionPackage) => ({
              packageVersionId: t.latestVersionId,
              options: t.options,
              name: t.pkgName,
              description: t.description,
            })),
            options: appletInstance.options,
          };
          onSubmit(variables);
        }}
        formData={formData}
        templates={{
          ButtonTemplates: {
            AddButton,
            SubmitButton,
          },
          ArrayFieldTitleTemplate,
          ArrayFieldDescriptionTemplate,
          TitleFieldTemplate,
          BaseInputTemplate,
          FieldTemplate: CustomFieldTemplate,
          ObjectFieldTemplate,
        }}
        omitExtraData={true}
      />
      <Spacer size="large"></Spacer>
      {onDelete ? (
        <Button
          onClick={() => onDelete(applet.id)}
          variant="warning"
          width="full"
        >
          Delete
        </Button>
      ) : null}
    </PageSection>
  );
};

export default Builder;
