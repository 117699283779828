import { useEffect, useState } from 'react';
import { PackageType, urqlGql } from '@pypestream/api-services';
import { GetPackagesByPackageTypeQuery } from '@pypestream/api-services/dist/generated/graphql.urql';

export const usePackages = (packageType: PackageType) => {
  // Use the PackageOption type in the state definition
  const [packages, setPackages] = useState<
    NonNullable<
      NonNullable<GetPackagesByPackageTypeQuery['admin_']>['packages']
    >
  >([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    urqlGql
      .getPackagesByPackageType({
        packageType: packageType,
      })
      .then((res) => {
        const mappedPackages = res.data?.admin_?.packages?.map((p) => {
          return {
            ...p,
            latestVersion: {
              ...p.latestVersion,
              schema:
                typeof p.latestVersion?.schema === 'string'
                  ? JSON.parse(p.latestVersion?.schema)
                  : p.latestVersion?.schema,
            },
          };
        });
        if (mappedPackages) {
          setPackages(mappedPackages);
        }
        setLoading(false);
      });
  }, [packageType]);

  return {
    loading,
    packages,
  };
};
