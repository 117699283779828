import {
  Card,
  Spacer,
  TextSubtitle,
  TextBody,
  Avatar,
} from '@pypestream/design-system';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { FC } from 'react';

import { ManagerContext } from '../../xstate/manager.xstate';

export interface ProjectCardProps {
  project: NonNullable<ManagerContext['projects']>[number];
  url: string;
}

export const ProjectCard: FC<ProjectCardProps> = ({ project, url }) => {
  return (
    <Card size="large" shadow="none" border="default" interactive href={url}>
      <Avatar
        size="large"
        src={project?.pictureFile?.url}
        label={project?.name}
        fallback="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIj4KICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNDREVCRUYiLz4KICAgICAgICAgICAgICAgICAgPG1hc2sgaWQ9InBhdGgtMi1pbnNpZGUtMV8zMTk2XzQ4MTUiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIvPgogICAgICAgICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIgc3Ryb2tlPSIjNjhDNENFIiBzdHJva2VXaWR0aD0iMyIgbWFzaz0idXJsKCNwYXRoLTItaW5zaWRlLTFfMzE5Nl80ODE1KSIvPgogICAgICAgICAgICAgICAgPC9zdmc+"
      />

      <Spacer size="2xsmall" />
      <TextSubtitle size="small">{project.name}</TextSubtitle>
      <Spacer size="2xsmall" />
      <TextBody size="small" variant="secondary">
        {formatDistanceToNow(parseISO(project.updatedAt), {
          addSuffix: true,
        })}
      </TextBody>
    </Card>
  );
};
