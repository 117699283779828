import {
  Avatar,
  BlockList,
  Button,
  Checkbox,
  Grid,
  GridCell,
  Input,
  PageSection,
  Spacer,
  Stack,
  TabPanel,
  TabPanelProps,
  TextBody,
  Text,
  TextSubtitle,
  TextTitle,
  Tooltip,
  Upload,
  Select,
  SelectOption,
  Divider,
  EntityTypes,
} from '@pypestream/design-system';
import { transformProfilePicture } from '@pypestream/utils';
import { ConsentStatus } from '@pypestream/api-services';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Loader } from '../../../components';
import {
  sendManagerEvent,
  sendUserEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useUserCtxSelector,
} from '../../../xstate/app.xstate';
import { MyAccountFormValues, useMyAccountForm } from '../../../hooks';
import { kratosApi } from '../../../kratos.api';
import {
  Countries,
  Languages,
  Timezones,
} from '../../../xstate/manager.xstate';

export const General: FC<TabPanelProps> = ({ active, name }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserCtxSelector((ctx) => ({
    user: ctx.user,
  }));
  const { userInfo, routes, countries, timeZones, languages } =
    useManagerCtxSelector((ctx) => ({
      userInfo: ctx.userInfo,
      routes: ctx.routes,
      countries: ctx.countries,
      timeZones: ctx.timezones,
      languages: ctx.languages.filter(({ languageCode }) => {
        return (
          !languageCode ||
          (
            ctx.localizationSettingsConfig?.user.supportedLanguageCodes || []
          ).includes(languageCode)
        );
      }),
    }));
  const userPreferencesUpdated = useManagerStateMatches(
    'orgRelated.ready.userInfo.updated'
  );

  const goToPreviousPage = useCallback(() => {
    // Redirect to the last page where you were on before you navigated to My Account page.
    const searchParams = new URLSearchParams(location.search);
    const returnUrl = searchParams.get('original_app_url');
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      navigate(-1);
    }
  }, [navigate, location.search]);

  useEffect(() => {
    if (!userPreferencesUpdated) return;
    goToPreviousPage();
  }, [goToPreviousPage, userPreferencesUpdated, routes.home]);

  const authMethod = userInfo?.recommendedAuthMethod;
  const isGoogleProvider = authMethod === 'google-sso' || authMethod === 'oidc';
  const loading = !user?.email || !authMethod;

  const formValues = useMemo<MyAccountFormValues>(
    () => ({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.settings?.jobTitle,
      profilePhoto: transformProfilePicture(user?.profilePhoto),
      email: user?.email,
      requiredConsentStatus: user?.requiredConsentStatus,
      optionalConsentStatus: user?.optionalConsentStatus,
      defaultLanguage: user?.settings?.defaultLanguage,
      defaultTimezone: user?.settings?.defaultTimezone,
      country: user?.settings?.country,
    }),
    [user]
  );

  const translatedLanguages = useMemo<Languages>(() => {
    if (languages.length > 0) {
      return [
        {
          name: t('manager/preferences:general.select_a_language'),
          id: '',
          locale: '',
          __typename: 'Locale',
          languageCode: '',
        },
        ...languages.slice(1),
      ];
    }

    return [];
  }, [languages, t]);

  const translatedTimeZones = useMemo<Timezones>(() => {
    if (timeZones.length > 0) {
      return [
        {
          label: t('manager/preferences:general.select_a_time_zone'),
          identifier: '',
          id: '',
          __typename: 'TimeZone',
        },
        ...timeZones.slice(1),
      ];
    }

    return [];
  }, [timeZones, t]);

  const translatedCountries = useMemo<Countries>(() => {
    if (countries.length > 0) {
      return [
        {
          name: t('manager/preferences:general.select_a_country'),
          id: '',
          code: '',
          __typename: 'Country2',
        },
        ...countries.slice(1),
      ];
    }

    return [];
  }, [countries, t]);

  const { form, watchedValues, onSubmit, setValue } = useMyAccountForm({
    formValues,
    onSubmit: (values: MyAccountFormValues) => {
      sendManagerEvent({
        type: 'manager.updateUserPreferences',
        values: {
          ...values,
        },
      });
    },
  });

  useEffect(() => {
    if (!user) return;

    form.reset({
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.settings?.jobTitle,
      profilePhoto: transformProfilePicture(user?.profilePhoto),
      email: user?.email,
      requiredConsentStatus: user?.requiredConsentStatus,
      optionalConsentStatus: user?.optionalConsentStatus,
      defaultLanguage: user?.settings?.defaultLanguage,
      defaultTimezone: user?.settings?.defaultTimezone,
      country: user?.settings?.country,
    });
  }, [form, user]);

  const redirectToResetPasswordPage = async () => {
    if (!watchedValues.email) return;

    const { data: logoutFlow } = await kratosApi.createBrowserLogoutFlow();
    await kratosApi.updateLogoutFlow({
      token: logoutFlow.logout_token,
    });

    sendUserEvent({
      type: 'user.signOut',
    });

    window.location.href = `${import.meta.env.FE_AUTH_URL}/recovery?emailId=${
      watchedValues.email
    }`;
  };

  const formElements = {
    get firstName() {
      return (
        <Input
          {...form.register('firstName')}
          placeholder={`${t('manager/preferences:general.add_name')}`}
          disabled={isGoogleProvider}
          variant="outlined"
          required
        />
      );
    },
    get lastName() {
      return (
        <Input
          {...form.register('lastName')}
          placeholder={`${t('manager/preferences:general.add_surname')}`}
          disabled={isGoogleProvider}
          variant="outlined"
          required
        />
      );
    },
    get email() {
      return (
        <Input
          {...form.register('email')}
          placeholder={`${t('manager/preferences:general.add_email_address')}`}
          type="email"
          disabled
          variant="outlined"
        />
      );
    },
    get jobTitle() {
      return (
        <Input
          {...form.register('jobTitle')}
          variant="outlined"
          placeholder={`${t('manager/preferences:general.add_job_title')}`}
        />
      );
    },
    get cookiePolicy() {
      return (
        <Stack gutter="xsmall" alignItems="center">
          <Checkbox
            size="small"
            {...form.register('optionalConsentStatus')}
            defaultChecked={
              formValues.optionalConsentStatus === ConsentStatus.Accepted
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                setValue('optionalConsentStatus', ConsentStatus.Accepted, {
                  shouldDirty: true,
                });
              } else {
                setValue('optionalConsentStatus', ConsentStatus.Declined, {
                  shouldDirty: true,
                });
              }
            }}
          >
            <TextBody size="small">
              {t('manager/preferences:general.cookie_policy_label_prefix')}
              &nbsp;
              <a
                href="https://app.termly.io/document/cookie-policy/84d2833f-fd93-4e1e-bcf7-4b18f161aa77"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.cookie_policy')}
              </a>
              &nbsp;
              {t('manager/preferences:general.cookie_policy_label_suffix')}
            </TextBody>
          </Checkbox>
        </Stack>
      );
    },
    get privacyPolicy() {
      return (
        <>
          <Stack
            gutter="xsmall"
            alignItems="center"
            style={{ display: 'none' }}
          >
            <Select
              {...form.register('requiredConsentStatus')}
              defaultValue={formValues.requiredConsentStatus}
              onChange={(e) => {
                setValue(
                  'requiredConsentStatus',
                  e.target.value as ConsentStatus,
                  {
                    shouldDirty: true,
                  }
                );
              }}
            >
              {Object.keys(ConsentStatus).map((status) => (
                <SelectOption key={status} value={status.toUpperCase()}>
                  {status}
                </SelectOption>
              ))}
            </Select>
          </Stack>

          <Text size="3xsmall">
            <Stack gutter="xsmall" alignItems="center">
              <a
                href="https://app.termly.io/document/privacy-policy/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.privacy_policy')}
              </a>
              <Divider vertical />
              <a
                href="https://app.termly.io/document/terms-of-service/85363fa8-fff8-4aeb-963e-cf55bbe4ef7f"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.terms_conditions')}
              </a>
              <Divider vertical />
              <a
                href="https://app.termly.io/notify/ac1820c8-6c9c-4ae8-a99f-09c17cec85f1"
                target="_blank"
                rel="noreferrer"
              >
                {t('manager/preferences:general.dsar_form')}
              </a>
            </Stack>
          </Text>
        </>
      );
    },
    get profilePhoto() {
      return (
        <>
          <Upload
            accountId={userInfo?.defaultOrgId}
            entityId={userInfo?.id}
            entityType={EntityTypes.USER}
            accept="image/png, image/jpeg"
            readonly={isGoogleProvider}
            type={watchedValues.profilePhoto ? 'success' : 'default'}
            text={`${t(
              `manager/preferences:general.${
                isGoogleProvider
                  ? 'upload_profile_picture_on_google'
                  : 'upload_a_file_here'
              }`
            )}`}
            cta={!isGoogleProvider ? 'Replace image' : ''}
            onChange={(e) => {
              if (!e.target.value) {
                return;
              }

              setValue('profilePhoto', e.target.value, {
                shouldDirty: true,
              });
            }}
          >
            <Avatar
              slot="preview"
              size="2xlarge"
              src={watchedValues.profilePhoto}
              label={`${watchedValues.firstName || 'Firsname'} ${
                watchedValues.lastName || 'Lastname'
              }`}
            />
          </Upload>
        </>
      );
    },
    get defaultLanguage() {
      return (
        <Select
          {...form.register('defaultLanguage')}
          defaultValue={formValues.defaultLanguage}
          variant="outlined"
          onChange={(e) => {
            setValue('defaultLanguage', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.applies_only_to_manager')}`}
        >
          {translatedLanguages.map((language) => (
            <SelectOption key={language.id} value={language.locale}>
              {language.name}
            </SelectOption>
          ))}
        </Select>
      );
    },
    get defaultTimezone() {
      return (
        <Select
          {...form.register('defaultTimezone')}
          defaultValue={formValues.defaultTimezone}
          variant="outlined"
          onChange={(e) => {
            setValue('defaultTimezone', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.applies_only_to_manager')}`}
        >
          {translatedTimeZones.map((timeZone) => (
            <SelectOption key={timeZone.id} value={timeZone.id}>
              {timeZone.label}
            </SelectOption>
          ))}
        </Select>
      );
    },
    get country() {
      return (
        <Select
          {...form.register('country')}
          defaultValue={formValues.country}
          variant="outlined"
          onChange={(e) => {
            setValue('country', e.target.value as string, {
              shouldDirty: true,
            });
          }}
          helpText={`${t('manager/preferences:general.the_country_you_are_a_citizen_of')}`}
        >
          {translatedCountries.map((country) => (
            <SelectOption key={country.id} value={country.code}>
              {country.name}
            </SelectOption>
          ))}
        </Select>
      );
    },
  };

  return loading ? (
    <Loader relative />
  ) : (
    <TabPanel name={name} active={active}>
      <Spacer size="xlarge" />
      <form onSubmit={onSubmit}>
        <PageSection>
          <TextTitle
            size="xsmall"
            i18nKey="manager/preferences:general.details"
          >
            Details
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.name"
                >
                  Name*
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Grid alignItems="center">
                  <GridCell xsmall="12" medium="6">
                    {formElements.firstName}
                  </GridCell>
                  <GridCell xsmall="12" medium="6">
                    {formElements.lastName}
                  </GridCell>
                </Grid>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.email_address"
                >
                  Email address*
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                <Tooltip content="To edit your email address, contact an Admin">
                  {formElements.email}
                </Tooltip>
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.job_title"
                >
                  Job Title
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.jobTitle}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.profile_picture"
                >
                  Profile Picture
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.profilePhoto}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.cookie_policy"
                >
                  Cookie Policy
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.cookiePolicy}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.other_policies"
                >
                  Other Policies
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.privacyPolicy}
              </GridCell>
            </Grid>

            <Grid alignItems="center">
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.reset_password"
                >
                  Reset Password
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {!isGoogleProvider ? (
                  <Button
                    variant="secondary"
                    disabled={!watchedValues.email}
                    i18nKey="manager/preferences:general.reset_password"
                    onClick={redirectToResetPasswordPage}
                  >
                    Reset password
                  </Button>
                ) : (
                  <TextBody i18nKey="manager/preferences:general.reset_password_help">
                    To reset your password, visit your Google account
                  </TextBody>
                )}
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>

        <PageSection>
          <TextTitle
            size="xsmall"
            i18nKey="manager/preferences:general.language_timezone"
          >
            Language & Timezone
          </TextTitle>
          <Spacer size="large" />

          <BlockList>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.country"
                >
                  Country
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.country}
              </GridCell>
            </Grid>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.default_timezone"
                >
                  Default timezone
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.defaultTimezone}
              </GridCell>
            </Grid>
            <Grid>
              <GridCell xsmall="12" medium="3">
                <TextSubtitle
                  size="small"
                  variant="secondary"
                  i18nKey="manager/preferences:general.default_language"
                >
                  Default language
                </TextSubtitle>
              </GridCell>
              <GridCell xsmall="12" medium="9">
                {formElements.defaultLanguage}
              </GridCell>
            </Grid>
          </BlockList>
        </PageSection>

        <Stack justifyContent="end" gutter="small">
          <Button
            variant="ghost"
            size="large"
            i18nKey="manager/common:cancel"
            onClick={goToPreviousPage}
          >
            Cancel
          </Button>
          <Button
            size="large"
            type="submit"
            disabled={!Object.keys(form.formState.dirtyFields).length}
            i18nKey="manager/common:save"
          >
            Save Changes
          </Button>
        </Stack>
      </form>
    </TabPanel>
  );
};
