import { AppletInstance, DomainTypeEnum } from '@pypestream/api-services';
import {
  BlockList,
  Button,
  Divider,
  Icon,
  PageSection,
  Spacer,
  Stack,
  TextOverline,
  TextSubtitle,
  TextTitle,
  TabButton,
} from '@pypestream/design-system';
import {
  TranslationComponent,
  TranslationKeys,
} from '@pypestream/translations';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';

import { ProjectEnvironmentField } from '.';
import {
  AppletGQLPlaygroundButton,
  ReleaseChannels,
  ReturnFormType,
  ProjectEnvironmentsType,
} from '../../../../components';
import { EnvironmentApplets } from './environment-applets';
import { useNavigate } from 'react-router-dom';
import { useManagerCtxSelector } from '../../../../xstate/app.xstate';

interface ProjectSettingsEnvironmentsTabProps {
  form: ReturnFormType;
  accountId?: string;
  projectId: string;
  projectEnvironments: ProjectEnvironmentsType;
  disabled?: boolean;
  environmentId?: string;
}

const defaultDomain = { type: DomainTypeEnum.Domain, url: '' };

export const ProjectSettingsEnvironmentsTab: FC<
  ProjectSettingsEnvironmentsTabProps
> = ({
  form,
  accountId,
  projectId,
  disabled,
  environmentId,
  projectEnvironments,
}) => {
  const navigate = useNavigate();

  const { routes } = useManagerCtxSelector((ctx) => {
    return {
      routes: ctx.routes,
    };
  });

  const { control, getFieldState, getValues } = form;

  // fetch current environment
  const environment = (() => {
    if (environmentId && projectId) {
      return projectEnvironments?.find(
        (channel) => channel?.id === environmentId
      );
    }
    // Fallback: If not found, find the channel where the name is 'Development'
    const fallbackChannel = projectEnvironments?.find(
      (channel) => channel?.name === ReleaseChannels.Development
    );
    return fallbackChannel;
  })();

  // Get the index of the found release channel dynamically
  const environmentIndex = projectEnvironments?.findIndex(
    (channel) => channel?.id === environment?.id
  );

  // Dynamically create the field array for the found release channel
  const environmentField = useFieldArray({
    control,
    name: `projectReleaseChannelConfigs.${environmentIndex}.domains`,
    rules: {
      minLength: 1,
    },
  });
  const [releaseChannelId] = getValues([
    `projectReleaseChannelConfigs.${environmentIndex}.id`,
  ]);

  const [applets] = getValues(['applets']);

  return (
    <>
      <TextTitle
        size="small"
        i18nKey="manager/projects:projectDetails.environmentsTab.title"
      >
        Deployment Overview
      </TextTitle>
      <Spacer size="2xlarge" />
      <PageSection>
        <TextTitle
          size="xsmall"
          i18nKey="manager/projects:projectDetails.environmentsTab.caption"
        >
          Environments
        </TextTitle>
        <TextSubtitle
          size="small"
          variant="tertiary"
          i18nKey="manager/projects:projectDetails.environmentsTab.description"
        >
          Projects organize work around an online property like a website.
          Everything in the project must be tied to a domain and, optionally,
          its subdomains.
        </TextSubtitle>

        <Spacer size="large" />

        <Stack
          display="flex"
          alignItems="center"
          justifyContent="end"
          gutter="xsmall"
        >
          {projectEnvironments?.flatMap((channel, index) => {
            return (
              <React.Fragment key={index}>
                <TabButton
                  key={index}
                  variant={
                    channel?.name === ReleaseChannels.Testing
                      ? 'staging'
                      : channel?.name === ReleaseChannels.Production
                        ? 'prod'
                        : 'dev'
                  }
                  selected={channel?.id === environmentId}
                  onClick={() => {
                    navigate(
                      `${routes.projects}/${projectId}/environments/${channel?.id}`,
                      { replace: true }
                    );
                  }}
                >
                  <TranslationComponent
                    i18nKey={`manager/projects:projectDetails.environmentsTab.environmentName`}
                    values={{
                      environmentName: channel?.name,
                    }}
                  >
                    {channel?.name}
                  </TranslationComponent>
                </TabButton>
                {projectEnvironments?.length !== index + 1 && (
                  <span
                    style={
                      {
                        opacity: 0.2,
                        color: '#8573e7',
                        display: 'flex',
                        alignItems: 'center',
                      } as React.CSSProperties
                    }
                  >
                    <Icon
                      name="chevron-right"
                      size="xsmall"
                      style={{ marginRight: '-13px' }}
                    ></Icon>
                    <Icon name="chevron-right" size="small"></Icon>
                  </span>
                )}
              </React.Fragment>
            );
          })}
        </Stack>
        <Spacer size="large" />
        <PageSection>
          <Stack
            gutter="2xsmall"
            alignItems="center"
            justifyContent="space-between"
            nowrap
          >
            <TextOverline>
              <TranslationComponent
                i18nKey={`manager/projects:projectDetails.environmentsTab.environmentName`}
                values={{
                  environmentName: environment?.name?.toLocaleUpperCase(),
                }}
              >
                {environment?.name?.toLocaleUpperCase()}
              </TranslationComponent>
            </TextOverline>
            <AppletGQLPlaygroundButton
              accountId={accountId}
              disabled={disabled}
              environmentId={environment?.id as string}
              i18nKey={`manager/projects:projectDetails.environmentsTab.gqlPlaygroundsButton`}
            />
          </Stack>
          <Spacer size="small" />
          <BlockList gutter="small">
            {environmentField.fields.map(({ id }, index) => (
              <Stack
                key={id}
                gutter="2xsmall"
                alignItems="center"
                justifyContent="space-between"
                nowrap
              >
                <ProjectEnvironmentField
                  disabled={disabled}
                  name={`projectReleaseChannelConfigs.${environmentIndex}.domains`}
                  index={index}
                  remove={environmentField.remove}
                  isForbiddenForRemove={!index}
                  form={form}
                  validate={
                    getFieldState(
                      `projectReleaseChannelConfigs.${environmentIndex}.domains.${index}.url`
                    ).isDirty
                  }
                  placeholder={
                    index === 0 ? 'https://www.pypestream.com/product/' : ''
                  }
                />
              </Stack>
            ))}
          </BlockList>

          <Spacer size="small" />
          <Button
            variant="ghost"
            size="large"
            disabled={disabled}
            onClick={() => environmentField.append(defaultDomain)}
          >
            <TranslationComponent
              i18nKey={`manager/projects:projectDetails.environmentsTab.addEnvironmentUrl`}
              values={{
                environmentName: environment?.name || 'Environment',
              }}
            >
              {`Add ${environment?.name} URL`}
            </TranslationComponent>
            <Icon slot="prefix" name="add" />
          </Button>
          <Spacer size="small" />
          <Divider></Divider>
        </PageSection>
        <EnvironmentApplets
          applets={applets.filter(
            (applet: AppletInstance) =>
              applet.projectReleaseChannelConfigId === releaseChannelId
          )}
          disabled={disabled}
          accountId={accountId}
          projectId={projectId}
          environmentId={releaseChannelId}
        />
      </PageSection>
    </>
  );
};
