import {
  Action,
  Package,
  PackageType,
  Trigger,
} from '@pypestream/api-services';
import SchemaField from '@rjsf/core/lib/components/fields/SchemaField';
import { toIdSchema, WidgetProps } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import React, { useEffect } from 'react';
import { usePackages } from '../use-packages';
import { Spinner } from '@pypestream/design-system';
import { PackageVersionsSelect } from './triggers-widget/package-versions-select';
import { includePrereleaseFilter } from '../utils/include-prerelease-filter';

export type ActionPackage = Package &
  Pick<Action, 'options'> & { packageVersionId: string };

export const ActionsWidget: React.FC<WidgetProps> = (props) => {
  const {
    value = [],
    onChange,
    onBlur,
    onFocus,
    registry,
    formContext,
  } = props;

  const { packages, loading } = usePackages(PackageType.Action);

  useEffect(() => {
    // if value is loaded with schemas then return
    if (value?.[0]?.latestVersionId) {
      return;
    }
    // @todo - Need refactor, ideally action widget should alway know which action is selected.
    // if no actions selected we should use formContext (applet type)
    if (!value?.length && formContext.actions?.length && packages.length) {
      const action = packages.find((p) =>
        formContext.actions.includes(p.pkgName)
      );
      onChange([{ ...action, options: {} }]);
      // if there is value without schema then get schema from packages
    } else if (packages.length && value.length) {
      const schemas: ActionPackage[] = value.map(
        ({ options, name }: Trigger) => {
          const pkg = packages.find((p) => p.pkgName === name);
          return {
            ...pkg,
            options,
          };
        }
      );
      onChange(schemas);
    }
  }, [packages, value, formContext.actions]);

  if (loading || !value?.[0]?.latestVersionId) {
    return <Spinner />;
  }

  return (
    <div>
      {value.map((actionSchema: ActionPackage, index: number) => {
        if (
          !actionSchema.latestVersion?.schema ||
          !actionSchema.latestVersionId
        ) {
          return <div key={index}>Schema not found</div>;
        }
        return (
          <React.Fragment key={index}>
            <PackageVersionsSelect
              versions={
                packages
                  .find((p) => p.pkgName === value[0].pkgName)
                  ?.versions?.filter((v) =>
                    includePrereleaseFilter(
                      v.version,
                      formContext.includePrerelease
                    )
                  ) || []
              }
              value={actionSchema.packageVersionId || 'latest'}
              onChange={(e) =>
                onChange(
                  value.with(0, {
                    ...value[0],
                    packageVersionId: e.target.value,
                  })
                )
              }
            />
            <br />
            <SchemaField
              readonly={false}
              disabled={false}
              {...props}
              onChange={(options) => {
                onChange(value.with(index, { ...value[index], options }));
              }}
              value={value}
              onBlur={onBlur}
              formData={actionSchema.options}
              onFocus={onFocus}
              uiSchema={{
                codeBlock: {
                  'ui:widget': 'CodeBlockWidget',
                },
              }}
              name={actionSchema.latestVersionId}
              registry={registry}
              idSchema={toIdSchema(
                validator,
                actionSchema.latestVersion.schema,
                `actions`
              )}
              schema={actionSchema.latestVersion.schema}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};
