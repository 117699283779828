import React, { useEffect, useRef } from 'react';
import {
  sendManagerEvent,
  useManagerCtxSelector,
  useManagerStateMatches,
  useManagerStateMatchesAllOf,
} from '../../../xstate/app.xstate';
import {
  dateFnsLocales,
  fallbackLanguage,
  TranslationComponent,
  useTranslation,
} from '@pypestream/translations';
import {
  Avatar,
  Button,
  ButtonGroup,
  DataTable,
  DataTableWC,
  Icon,
  PageBody,
  PageSection,
  Spacer,
  Stack,
  Text,
  TextTitle,
} from '@pypestream/design-system';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../components';
import { html } from 'lit/static-html.js';
import { formatDistance, parseISO } from 'date-fns';

export const Organizations: React.FC = () => {
  const loaded = useManagerStateMatches('orgRelated.ready.childOrgs.loaded');
  const isReadyToLoad = useManagerStateMatchesAllOf([
    'orgRelated.ready.childOrgs.idle',
    'orgRelated.ready.currentOrg.selected',
  ]);

  const { childOrgs, defaultLanguage, defaultOrgId } = useManagerCtxSelector(
    (ctx) => ({
      childOrgs: ctx.childOrgs,
      defaultLanguage: ctx.userInfo?.defaultLanguage,
      defaultOrgId: ctx.userInfo?.defaultOrgId,
    })
  );

  const navigate = useNavigate();

  const tableRef = useRef<DataTableWC>(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (isReadyToLoad) {
      sendManagerEvent('manager.childOrgs.loadChildOrgs');
    }
  }, [isReadyToLoad]);

  return (
    <>
      <PageBody background="none" width="large">
        <>
          {!loaded ? (
            <PageSection>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader relative />
              </div>
            </PageSection>
          ) : (
            <>
              <Stack alignItems="center" justifyContent="space-between">
                <Stack
                  gutter="small"
                  justifyContent="start"
                  alignItems="center"
                  direction="row"
                  nowrap
                >
                  <Avatar
                    size="2xlarge"
                    src={childOrgs.currentOrg?.picture}
                    fallback="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIj4KICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNDREVCRUYiLz4KICAgICAgICAgICAgICAgICAgPG1hc2sgaWQ9InBhdGgtMi1pbnNpZGUtMV8zMTk2XzQ4MTUiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIvPgogICAgICAgICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIgc3Ryb2tlPSIjNjhDNENFIiBzdHJva2VXaWR0aD0iMyIgbWFzaz0idXJsKCNwYXRoLTItaW5zaWRlLTFfMzE5Nl80ODE1KSIvPgogICAgICAgICAgICAgICAgPC9zdmc+"
                  ></Avatar>
                  <Stack gutter="none" display="inline-flex" direction="column">
                    <TextTitle size="small">
                      {childOrgs.currentOrg?.name}
                    </TextTitle>
                    <Text
                      size="2xsmall"
                      font-weight="normal"
                      variant="secondary"
                    >
                      {t('manager/organizations:childOrgsList.created', {
                        date: formatDistance(
                          parseISO(childOrgs.currentOrg?.createdAt),
                          new Date(),
                          {
                            addSuffix: true,
                            locale:
                              dateFnsLocales[
                                defaultLanguage || fallbackLanguage
                              ],
                          }
                        ),
                      })}
                    </Text>
                  </Stack>
                </Stack>
                <ButtonGroup>
                  <Button
                    size="large"
                    onClick={() =>
                      navigate(
                        `/organization/${defaultOrgId}/child-orgs/${childOrgs.currentOrg?.id}`
                      )
                    }
                  >
                    <Icon slot="prefix" name="edit" size="xsmall" />
                    <TranslationComponent i18nKey="manager/organizations:childOrgsList.editOrg">
                      Edit Org
                    </TranslationComponent>
                  </Button>
                </ButtonGroup>
              </Stack>
              <Spacer size="2xlarge" />
              <PageSection>
                <Stack alignItems="center" justifyContent="space-between">
                  <TextTitle size="xsmall">
                    {t('manager/organizations:childOrgsList.childOrgs', {
                      count: childOrgs.count,
                      defaultValue: 'Child Organizations ({{count}})',
                    })}
                  </TextTitle>
                  <ButtonGroup>
                    <Button size="large" disabled>
                      <Icon slot="prefix" name="plus" size="xsmall" />
                      <TranslationComponent i18nKey="manager/organizations:childOrgsList.createOrg">
                        Create Org
                      </TranslationComponent>
                    </Button>
                  </ButtonGroup>
                </Stack>
                <Spacer size="large"></Spacer>
                <DataTable
                  ref={tableRef}
                  columns={[
                    {
                      accessorKey: 'name',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.name', {
                          defaultValue: 'Name',
                        }) || '',
                      cell: ({ row, renderValue }) => html`
                        <ps-stack
                          gutter="small"
                          justifyContent="start"
                          alignItems="center"
                          direction="row"
                          nowrap
                        >
                          <ps-avatar
                            src="${row.original.picture}"
                            fallback="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OCIgaGVpZ2h0PSI0OCIgdmlld0JveD0iMCAwIDQ4IDQ4IiBmaWxsPSJub25lIj4KICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNDREVCRUYiLz4KICAgICAgICAgICAgICAgICAgPG1hc2sgaWQ9InBhdGgtMi1pbnNpZGUtMV8zMTk2XzQ4MTUiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIvPgogICAgICAgICAgICAgICAgICA8L21hc2s+CiAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yOS4zMzk3IDI0QzMzLjAxODEgMjQgMzYuMTM3IDIwLjgwMjYgMzQuMTk4NiAxNy42NzY0QzM0LjE4NDcgMTcuNjUzOSAzNC4xNzA3IDE3LjYzMTQgMzQuMTU2NSAxNy42MDlDMzIuOTUyMSAxNS42OTUgMzEuMjMxMyAxNC4xNjAyIDI5LjE5MjYgMTMuMTgxNkMyNy4xNTM4IDEyLjIwMyAyNC44Nzk5IDExLjgyMDUgMjIuNjMzMiAxMi4wNzgxQzIwLjM4NjQgMTIuMzM1NyAxOC4yNTgyIDEzLjIyMjkgMTYuNDkzOCAxNC42Mzc1QzE0LjcyOTMgMTYuMDUyMSAxMy40MDA2IDE3LjkzNjUgMTIuNjYwNiAyMC4wNzM1QzExLjkyMDYgMjIuMjEwNCAxMS43OTk1IDI0LjUxMyAxMi4zMTE0IDI2LjcxNThDMTIuODIzMiAyOC45MTg2IDEzLjk0NyAzMC45MzIgMTUuNTUzNCAzMi41MjM4QzE1LjU3MjIgMzIuNTQyNCAxNS41OTExIDMyLjU2MSAxNS42MSAzMi41Nzk1QzE4LjIzOTkgMzUuMTUxMyAyMi4wMzkgMzIuODAyMyAyMi44Mzg5IDI5LjIxMkMyMy41MTc0IDI2LjE2NjMgMjYuMjE5NCAyNCAyOS4zMzk3IDI0WiIgc3Ryb2tlPSIjNjhDNENFIiBzdHJva2VXaWR0aD0iMyIgbWFzaz0idXJsKCNwYXRoLTItaW5zaWRlLTFfMzE5Nl80ODE1KSIvPgogICAgICAgICAgICAgICAgPC9zdmc+"
                          ></ps-avatar>
                          <ps-stack
                            gutter="none"
                            display="inline-flex"
                            direction="column"
                          >
                            <ps-text-subtitle size="small"
                              >${row.original.name}</ps-text-subtitle
                            >
                          </ps-stack>
                        </ps-stack>
                      `,
                    },
                    {
                      accessorKey: 'users',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.users', {
                          defaultValue: 'Users',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.users?.count || '0'}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'teams',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.teams', {
                          defaultValue: 'Teams',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.teams?.rows
                            ?.map?.((team) => team.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'projects',
                      header: () =>
                        t(
                          'manager/organizations:childOrgsList.table.projects',
                          {
                            defaultValue: 'Projects',
                          }
                        ) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.projects?.rows
                            ?.map?.((project) => project.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'organizations',
                      header: () =>
                        t(
                          'manager/organizations:childOrgsList.table.childOrgs',
                          {
                            defaultValue: 'Child Orgs',
                          }
                        ) || '',
                      cell: ({ row, renderValue }) =>
                        html`<ps-text-body variant="secondary"
                          >${row.original.allChildAccounts
                            ?.map?.((org) => org.name)
                            .join(', ')}</ps-text-body
                        >`,
                    },
                    {
                      accessorKey: 'updated',
                      header: () =>
                        t('manager/organizations:childOrgsList.table.updated', {
                          defaultValue: 'Last updated',
                        }) || '',
                      cell: ({ row, renderValue }) =>
                        html`<div style="min-width: 70px">
                          <ps-text-body variant="secondary" size="small">
                            ${formatDistance(
                              parseISO(row.original.updatedAt),
                              new Date(),
                              {
                                addSuffix: true,
                                locale:
                                  dateFnsLocales[
                                    defaultLanguage || fallbackLanguage
                                  ],
                              }
                            )}</ps-text-body
                          >
                        </div>`,
                    },
                  ]}
                  data={childOrgs.rows}
                  onRowSelectionChange={(event) => {
                    if (event.detail.selected && event.detail.selected.length) {
                      event.detail.selected.map((item) => {
                        const dataTable = event.currentTarget as DataTableWC;

                        dataTable['table'].resetRowSelection();

                        sendManagerEvent({
                          type: 'manager.selectChildOrg',
                          id: item.original.id,
                        });

                        navigate(
                          `/organization/${defaultOrgId}/child-orgs/${item.original.id}`
                        );

                        return item;
                      });
                    }
                  }}
                  enableRowSelection
                ></DataTable>

                {!childOrgs ||
                  (!childOrgs.rows?.length && (
                    <Text
                      size="xsmall"
                      textAlign="center"
                      className="u-margin-top-xlarge"
                      i18nKey="manager/organizations:childOrgsList.createToGetStarted"
                    >
                      Create an org to get started.
                    </Text>
                  ))}
              </PageSection>
            </>
          )}
        </>
      </PageBody>
    </>
  );
};
